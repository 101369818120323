import { createTheme } from '@mui/material'
import '@fontsource/be-vietnam-pro'

declare module '@mui/material/styles' {
  interface PaletteColor {
    semiTransparent?: string
  }

  interface SimplePaletteColorOptions {
    semiTransparent?: string
  }
}

const eiduTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5c3cd8',
      semiTransparent: '#ede7faaa',
    },
    secondary: {
      main: '#f3c049',
    },
  },
  typography: {
    fontFamily: '"Be Vietnam Pro"',
  },
})

export default eiduTheme
