import apiBaseUrl from './baseUrl'
import bearerTokenHeader from '../authorization/bearerTokenHeader'
import AuthenticationContext from '../authorization/AuthenticationContext'

type PostFormContentTextParameters = {
  useYamlFormat: boolean
  formContentText: string
  authContext: AuthenticationContext
}

const postFormContentText = async ({
  useYamlFormat,
  formContentText,
  authContext,
}: PostFormContentTextParameters): Promise<string> => {
  const response = await fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/form-contents-text`, {
    method: 'POST',
    headers: useYamlFormat
      ? { ...bearerTokenHeader(authContext.accessToken), 'Content-Type': 'application/yaml' }
      : bearerTokenHeader(authContext.accessToken),
    body: useYamlFormat ? formContentText : JSON.stringify({ formContentText }),
  })
  const responseJson = await response.json()
  if (response.status !== 200) throw Error(responseJson.error.message)

  return responseJson.contentId
}

export default postFormContentText
