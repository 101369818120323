import { com, kotlin } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import serializeToJsonString = com.eidu.sharedlib.entity.util.serializeToJsonString
import PostTypesRequest = com.eidu.sharedlib.entity.api.types.PostTypesRequest
import KtList = kotlin.collections.KtList
import EntityTypeToCreate = com.eidu.sharedlib.entity.api.types.EntityTypeToCreate
import Field = com.eidu.sharedlib.entity.field.Field
import EntityLabel = com.eidu.sharedlib.entity.label.EntityLabel
import PostTypesResponse = com.eidu.sharedlib.entity.api.types.PostTypesResponse
import EntityTypeKind = com.eidu.sharedlib.entity.type.EntityTypeKind

export type PostEntityTypeParameters = {
  name: string
  fieldsToCreate: readonly Field[]
  label: EntityLabel
  kind: EntityTypeKind
  authContext: AuthenticationContext
}

const postEntityType = ({
  name,
  fieldsToCreate,
  label,
  kind,
  authContext,
}: PostEntityTypeParameters): Promise<EntityType> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/types`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
    body: serializeToJsonString(
      new PostTypesRequest(
        KtList.fromJsArray([new EntityTypeToCreate(name, KtList.fromJsArray(fieldsToCreate), label, kind)])
      )
    ),
    method: 'POST',
  }).then(async (response) => {
    if (response.status !== 200)
      throw Error(`Unexpected response code ${response.status} from postEntityTypes endpoint`)

    const types = PostTypesResponse.fromJsonString(await response.text()).types.asJsReadonlyArrayView()
    if (types.length === 1) return types[0]
    else throw Error(`Unexpected number of type ids returned from postEntityTypes endpoint: ${types.length}`)
  })

export default postEntityType
