import { AppBar, Button, Stack, Toolbar } from '@mui/material'

export type TopAppBarProps = {
  signOut?: () => void
}

const TopAppBar = ({ signOut }: TopAppBarProps) => (
  <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    <Toolbar>
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <img src="logo192.png" className="appBarIcon" alt="" />
        {signOut && (
          <Button variant="text" color="inherit" onClick={signOut}>
            Log out
          </Button>
        )}
      </Stack>
    </Toolbar>
  </AppBar>
)

export default TopAppBar
