import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import requireValidPaginationParameters from '../common/requireValidPaginationParameters'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import Entity = com.eidu.sharedlib.entity.Entity
import entityPaginatedResponseWithRelatedFromJsonString = com.eidu.sharedlib.entity.api.entities.entityPaginatedResponseWithRelatedFromJsonString
import EntityId = com.eidu.sharedlib.entity.EntityId
import PaginatedResponseWithRelated = com.eidu.sharedlib.entity.api.common.PaginatedResponseWithRelated

type GetEntitiesBatchParameters = {
  ids: EntityId[]
  withRelatedEntities?: boolean
  pageIndex: number
  pageSize: number
  authContext: AuthenticationContext
}

export const MAX_NUM_IDS_PER_BATCH = 50

const getEntitiesBatch = ({
  ids,
  withRelatedEntities,
  pageIndex,
  pageSize,
  authContext,
}: GetEntitiesBatchParameters): Promise<PaginatedResponseWithRelated<Entity>> => {
  requireValidPaginationParameters(pageIndex, pageSize)
  if (ids.length === 0) throw Error('ids must not be empty')
  if (ids.length > MAX_NUM_IDS_PER_BATCH)
    throw Error(`ids must not contain more than ${MAX_NUM_IDS_PER_BATCH} elements`)

  const encodedIds = encodeURIComponent(ids.map((it) => it.asString()).join(','))
  const related = withRelatedEntities ? 'true' : 'false'

  return fetch(
    `${apiBaseUrl}/organizations/${authContext.organizationId}/entities?ids=${encodedIds}&related=${related}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      headers: { Authorization: `Bearer ${authContext.accessToken}` },
    }
  ).then(async (response) => {
    if (response.status !== 200)
      throw Error(`Unexpected response code ${response.status} from getEntitiesBatch endpoint`)

    return entityPaginatedResponseWithRelatedFromJsonString(await response.text())
  })
}

export default getEntitiesBatch
