import {
  ColumnMenuPropsOverrides,
  GridColDef,
  GridColumnMenuContainer,
  GridColumnMenuItemProps,
  GridColumnMenuProps,
  GridRowSelectionModel,
} from '@mui/x-data-grid'
import React, { JSXElementConstructor, useEffect, useMemo, useState } from 'react'
import { com } from '@eidu/entity'
import { GridApiCommunity } from '@mui/x-data-grid/internals'
import { NavigateFunction } from 'react-router-dom'
import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import DataGridWithPageSelection from '../DataGridWithPageSelection'
import PaginationModel from '../../util/pagination/PaginationModel'
import getRenderFieldCell, { CellContent, cellContentComparator } from './getRenderFieldCell'
import EntityWithLabelAndRelated from '../../domain/entity/EntityWithLabelAndRelated'
import EqualityHashMap from '../../util/EqualityHashMap'
import EntityWithLabel from '../../domain/entity/EntityWithLabel'
import DraftFieldValue, { draftFieldValueToFieldValue, fieldValueToString } from '../../domain/entity/DraftFieldValue'
import { useSnackBar } from '../SnackBarProvider'
import hasFieldError from '../../domain/entity/hasFieldError'
import { EntityFormField } from './EntityForm'
import { logException } from '../../util/Logging'
import EntityRepository from '../../io/EntityRepository'
import useEntitiesOfType from '../../io/useEntitiesOfType'
import useDebounced from '../../ui/debounce/useDebounced'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import Field = com.eidu.sharedlib.entity.field.Field
import entityIdFromString = com.eidu.sharedlib.entity.entityIdFromString

const getColumns = (
  entityType: EntityType,
  entities: ReadonlyMap<EntityId, EntityWithLabel | null>,
  relatedTypes: ReadonlyMap<EntityTypeId, EntityType>
): readonly GridColDef[] => [
  { field: 'row', headerName: 'Row', disableColumnMenu: true },
  ...entityType.fields.asJsReadonlyArrayView().map(
    ({ id, name, type }): GridColDef => ({
      field: `field-${id.asString()}`,
      headerName: name,
      sortComparator: cellContentComparator,
      renderCell: getRenderFieldCell(type, entities, relatedTypes),
      display: 'flex',
    })
  ),
]

const getRows = (
  entities: readonly EntityWithLabelAndRelated[],
  entityType: EntityType,
  paginationModel: PaginationModel
): [string, CellContent | number][] =>
  entities.map((entity, index) =>
    Object.fromEntries([
      ...entityType.fields.asJsReadonlyArrayView().map(({ id }) => [
        `field-${id.asString()}`,
        {
          value: entity.entity.valuesByFieldId
            .asJsReadonlyMapView()
            .get(id)
            ?.let((it) => fieldValueToString(it)),
        },
      ]),
      ['id', entity.entity.id.asString()],
      ['row', paginationModel.page * paginationModel.pageSize + index + 1],
    ])
  )

type BulkEditMenuCustomProps = {
  fieldsById: Map<string, Field>
  selectedEntityIds: string[]
  entityRepository: EntityRepository
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
}
type BulkEditMenuItemCustomProps = {
  field: Field
  selectedEntityIds: string[]
  entityRepository: EntityRepository
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
}

const BulkEditMenuItem = ({
  field,
  selectedEntityIds,
  entityRepository,
  entityTypes,
  onClick,
}: GridColumnMenuItemProps & BulkEditMenuItemCustomProps) => {
  const { showSnackBar } = useSnackBar()

  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedSearchQuery] = useDebounced(searchQuery)

  const referenceableEntityTypeIds = Array.from(field.validReferenceTypes?.asJsReadonlySetView() ?? [])
  const referenceableEntityTypesById: Map<EntityTypeId, EntityType> = new EqualityHashMap(
    [...entityTypes].filter(([id]) => referenceableEntityTypeIds.some((it) => it.equals(id)))
  )
  const referenceableEntitiesByTypeId =
    useEntitiesOfType(
      {
        typeIds: referenceableEntityTypeIds,
        searchQuery: debouncedSearchQuery,
        allTypes: entityTypes,
      },
      [debouncedSearchQuery]
    ) ?? new Map()

  const defaultFieldValue = { fieldId: field.id, type: field.type, value: '' }
  const [fieldValue, setFieldValue] = useState<DraftFieldValue>(defaultFieldValue)

  const [isEditing, setIsEditing] = useState(false)

  const hasError = field && hasFieldError(field, fieldValue, referenceableEntitiesByTypeId)

  return (
    field && (
      <Stack padding={1} spacing={1}>
        <Typography variant="h6">Edit selected items</Typography>
        <EntityFormField
          key={`column-form-field-${field.id.asString()}`}
          field={field}
          fieldValue={fieldValue}
          referenceableEntityTypesById={referenceableEntityTypesById}
          referenceableEntitiesByTypeId={referenceableEntitiesByTypeId}
          updateField={setFieldValue}
          error={hasError}
          setSearchQuery={setSearchQuery}
        />
        <Stack direction="row" justifyContent="flex-end" sx={{ height: '30px' }}>
          <Button
            disabled={!selectedEntityIds.length || hasError || isEditing}
            onClick={async (event) => {
              setIsEditing(true)
              try {
                const fieldValuesById = new Map([[field.id, draftFieldValueToFieldValue(fieldValue)]])
                await entityRepository.modifyEntities(
                  new Map(selectedEntityIds.map((id) => [entityIdFromString(id), fieldValuesById]))
                )
                showSnackBar(`Updated ${selectedEntityIds.length} items`, 'success')
              } catch (e) {
                logException(e)
                showSnackBar(`Failed to update ${selectedEntityIds.length} items`, 'error')
              }
              setIsEditing(false)
              onClick(event)
            }}
          >
            {isEditing ? <CircularProgress size="30px" /> : `Apply (${selectedEntityIds.length})`}
          </Button>
        </Stack>
      </Stack>
    )
  )
}

const CustomColumnMenu = ({
  colDef,
  entityTypes,
  fieldsById,
  selectedEntityIds,
  entityRepository,
  ...props
}: GridColumnMenuProps & BulkEditMenuCustomProps) => (
  <GridColumnMenuContainer {...props} colDef={colDef} onWheel={(e) => e.stopPropagation()}>
    <BulkEditMenuItem
      colDef={colDef}
      onClick={props.hideMenu}
      field={fieldsById.get(colDef.field)!}
      selectedEntityIds={selectedEntityIds}
      entityRepository={entityRepository}
      entityTypes={entityTypes}
    />
  </GridColumnMenuContainer>
)

type ListEntityDataGridProps = {
  navigate: NavigateFunction
  entityRepository: EntityRepository
  entities: readonly EntityWithLabelAndRelated[]
  relatedTypes: ReadonlyMap<EntityTypeId, EntityType>
  entityType: EntityType
  paginationModel: PaginationModel
  setPaginationModel: (paginationModel: PaginationModel) => void
  searchQuery: string
  setSearchQuery: (searchQuery: string) => void
  rowCount: number
  loading: boolean
  apiRef?: React.MutableRefObject<GridApiCommunity>
}

const getIdMap = (entities: readonly EntityWithLabel[]): ReadonlyMap<EntityId, EntityWithLabel> =>
  new EqualityHashMap(entities.map((it) => [it.entity.id, it]))

const ListEntityDataGrid = ({
  navigate,
  entityRepository,
  entities,
  relatedTypes,
  entityType,
  paginationModel,
  setPaginationModel,
  searchQuery,
  setSearchQuery,
  rowCount,
  loading,
  apiRef,
}: ListEntityDataGridProps) => {
  const allEntities = entities.map((it) => [it, ...it.referencedEntities]).flat()
  const columns = useMemo(
    () => getColumns(entityType, getIdMap(allEntities), relatedTypes),
    [entityType, allEntities, relatedTypes]
  )

  const rows = useMemo(() => getRows(entities, entityType, paginationModel), [entities, entityType, paginationModel])

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
  useEffect(() => {
    setSelectionModel([])
  }, [entityType.id])

  const fieldsById = new Map(
    entityType.fields.asJsReadonlyArrayView().map((field) => [`field-${field.id.asString()}`, field])
  )

  const entityTypes = new EqualityHashMap([[entityType.id, entityType], ...relatedTypes.entries()])

  return (
    <Stack spacing={1}>
      <TextField
        label="Search"
        fullWidth
        value={searchQuery}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchQuery(event.target.value)
        }}
      />
      <DataGridWithPageSelection
        columns={columns}
        rows={rows}
        checkboxSelection
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
        rowCount={rowCount}
        loading={loading}
        apiRef={apiRef}
        onRowClick={(params) => {
          const id = entityIdFromString(params.row.id)
          if (id) navigate(`/entities/${entityType.id.asString()}/edit/${id.asString()}`)
        }}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={setSelectionModel}
        customColumnMenu={CustomColumnMenu as JSXElementConstructor<GridColumnMenuProps & ColumnMenuPropsOverrides>}
        columnMenuProps={
          { entityTypes, fieldsById, selectedEntityIds: selectionModel, entityRepository } as Partial<
            GridColumnMenuProps & ColumnMenuPropsOverrides
          >
        }
      />
    </Stack>
  )
}

export default ListEntityDataGrid
