import { com, Nullable } from '@eidu/entity'
import FieldData, { ExistingFieldData } from '../../domain/entity/FieldData'
import { requireNotUndefinedOrNull } from '../../util/require'
import createValidatedFieldValues, { EntityValidationError3D } from '../../domain/entity/createValidatedFieldValues'
import EntityValidationError = com.eidu.sharedlib.entity.validation.EntityValidationError
import TypeValidationError = com.eidu.sharedlib.entity.validation.TypeValidationError
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityTypeKind = com.eidu.sharedlib.entity.type.EntityTypeKind
import Failure = com.eidu.sharedlib.util.Failure

export type ValidationErrors = {
  entityValidationErrors?: EntityValidationError3D
  nameValidationErrors?: readonly TypeValidationError[]
  primaryLabelValidationErrors?: readonly TypeValidationError[]
  secondaryLabelValidationErrors?: readonly TypeValidationError[]
  fieldValidationErrors?: Nullable<readonly TypeValidationError[]>[]
}

export type FetchingEntityTypeDefinition = {
  entityTypeName: undefined
  fields: undefined
  existingFields: undefined
  primaryLabel: undefined
  secondaryLabel: undefined
  kind: undefined
  labelUpdated: false
  data: undefined
  file: undefined
  error: false
  errorMessage: undefined
  validationErrors: undefined
  submitting: false
}

export const fetchingEntityTypeDefinition = (): FetchingEntityTypeDefinition => ({
  entityTypeName: undefined,
  fields: undefined,
  existingFields: undefined,
  primaryLabel: undefined,
  secondaryLabel: undefined,
  kind: undefined,
  labelUpdated: false,
  data: undefined,
  file: undefined,
  error: false,
  errorMessage: undefined,
  validationErrors: undefined,
  submitting: false,
})

export type ErrorFetchingEntityTypeDefinition = {
  entityTypeName: undefined
  fields: undefined
  existingFields: undefined
  primaryLabel: undefined
  secondaryLabel: undefined
  kind: undefined
  labelUpdated: false
  data: undefined
  file: undefined
  error: true
  errorMessage: string
  validationErrors: undefined
  submitting: false
}

export const errorLoadingFieldType = (message: string): ErrorFetchingEntityTypeDefinition => ({
  entityTypeName: undefined,
  fields: undefined,
  existingFields: undefined,
  primaryLabel: undefined,
  secondaryLabel: undefined,
  kind: undefined,
  labelUpdated: false,
  data: undefined,
  file: undefined,
  error: true,
  errorMessage: message,
  validationErrors: undefined,
  submitting: false,
})

export type AwaitingCsvUpload = {
  entityTypeName: string
  existingFields: readonly ExistingFieldData[]
  fields: readonly FieldData[]
  primaryLabel: string | undefined
  secondaryLabel: string | undefined
  kind: EntityTypeKind | undefined
  labelUpdated: boolean
  data: undefined
  file: undefined
  error: false
  errorMessage: undefined
  validationErrors: undefined
  submitting: false
}

export const awaitingCsvUpload = (
  entityTypeName: string,
  existingFields: readonly ExistingFieldData[],
  primaryLabel?: string,
  secondaryLabel?: string,
  kind?: EntityTypeKind
): AwaitingCsvUpload => ({
  entityTypeName,
  existingFields,
  fields: existingFields,
  primaryLabel: primaryLabel || '',
  secondaryLabel: secondaryLabel || '',
  kind: kind ?? EntityTypeKind.Generic,
  labelUpdated: false,
  data: undefined,
  file: undefined,
  error: false,
  errorMessage: undefined,
  validationErrors: undefined,
  submitting: false,
})

export type ErrorLoadingCsv = {
  entityTypeName: string
  existingFields: readonly ExistingFieldData[]
  fields: undefined
  primaryLabel: undefined
  secondaryLabel: undefined
  kind: undefined
  labelUpdated: false
  data: undefined
  file: undefined
  error: true
  errorMessage: string
  validationErrors: undefined
  submitting: false
}

export const errorLoadingCsv = (currentState: PageState, message: string): ErrorLoadingCsv => ({
  entityTypeName: currentState.entityTypeName || '',
  existingFields: currentState.existingFields || [],
  fields: undefined,
  primaryLabel: undefined,
  secondaryLabel: undefined,
  kind: undefined,
  labelUpdated: false,
  data: undefined,
  file: undefined,
  error: true,
  errorMessage: message,
  validationErrors: undefined,
  submitting: false,
})

export type LoadedCsv = {
  entityTypeName: string
  existingFields: readonly ExistingFieldData[]
  fields: readonly FieldData[]
  primaryLabel?: string
  secondaryLabel?: string
  kind: EntityTypeKind | undefined
  labelUpdated: boolean
  data: readonly (readonly string[])[]
  file: File
  error: false
  errorMessage: undefined
  validationErrors?: ValidationErrors
  submitting: false
}

export const loadedCsv = (
  entityTypeName: string,
  existingFields: readonly ExistingFieldData[],
  fields: readonly FieldData[],
  primaryLabel: string | undefined,
  secondaryLabel: string | undefined,
  kind: EntityTypeKind | undefined,
  labelUpdated: boolean,
  data: readonly (readonly string[])[],
  file: File,
  validationErrors?: ValidationErrors
): LoadedCsv => ({
  entityTypeName,
  existingFields,
  fields,
  primaryLabel: primaryLabel || '',
  secondaryLabel: secondaryLabel || '',
  kind,
  labelUpdated,
  data,
  file,
  error: false,
  errorMessage: undefined,
  submitting: false,
  validationErrors,
})

export type SubmittingChanges = {
  entityTypeName: string
  existingFields: readonly ExistingFieldData[]
  fields: readonly FieldData[]
  primaryLabel: string
  secondaryLabel: string
  kind: EntityTypeKind | undefined
  labelUpdated: boolean
  data: readonly (readonly string[])[]
  file: File
  error: false
  errorMessage: undefined
  validationErrors: undefined
  submitting: true
}

export type ErrorSubmittingChanges = {
  entityTypeName: string
  existingFields: readonly ExistingFieldData[]
  fields: readonly FieldData[]
  primaryLabel: string | undefined
  secondaryLabel: string | undefined
  kind: EntityTypeKind | undefined
  labelUpdated: boolean
  data: readonly (readonly string[])[]
  file: File
  error: true
  errorMessage: undefined
  validationErrors: undefined
  submitting: false
}

type PageState =
  | FetchingEntityTypeDefinition
  | ErrorFetchingEntityTypeDefinition
  | AwaitingCsvUpload
  | ErrorLoadingCsv
  | LoadedCsv
  | SubmittingChanges
  | ErrorSubmittingChanges

export const withSubmitting = (state: LoadedCsv): SubmittingChanges => ({
  ...state,
  primaryLabel: requireNotUndefinedOrNull(state.primaryLabel),
  secondaryLabel: requireNotUndefinedOrNull(state.secondaryLabel),
  error: false,
  errorMessage: undefined,
  validationErrors: undefined,
  submitting: true,
})

export const withSubmissionError = (state: LoadedCsv | SubmittingChanges): ErrorSubmittingChanges => ({
  ...state,
  primaryLabel: requireNotUndefinedOrNull(state.primaryLabel),
  secondaryLabel: requireNotUndefinedOrNull(state.secondaryLabel),
  error: true,
  errorMessage: undefined,
  validationErrors: undefined,
  submitting: false,
})

export const withLabelUpdated = (state: LoadedCsv, labelUpdated: boolean): LoadedCsv => ({
  ...state,
  labelUpdated,
})

export const withEntityValidationErrors = (
  state: LoadedCsv,
  entityValidationErrors?: readonly (readonly (readonly EntityValidationError[])[])[]
): LoadedCsv => ({
  ...state,
  validationErrors: { ...(state.validationErrors || {}), entityValidationErrors },
})

export const withUpdatedEntityValidations = async (
  state: LoadedCsv,
  getEntityTypeIds: (ids: EntityId[]) => Promise<ReadonlyMap<EntityId, EntityTypeId>>
) => {
  const Outcome = await createValidatedFieldValues(state.data, state.fields, getEntityTypeIds)
  if (!(Outcome instanceof Failure)) return withEntityValidationErrors(state, undefined)
  else return withEntityValidationErrors(state, Outcome.value)
}

export const withNameValidationErrors = (
  state: LoadedCsv,
  nameValidationErrors?: readonly TypeValidationError[]
): LoadedCsv => ({
  ...state,
  validationErrors: { ...(state.validationErrors || {}), nameValidationErrors },
})

export const withFieldValidationErrors = (
  state: LoadedCsv,
  fieldValidationErrors: (readonly TypeValidationError[] | undefined)[] | undefined
): LoadedCsv => ({
  ...state,
  validationErrors: { ...(state.validationErrors || {}), fieldValidationErrors },
})

export const hasEntityValidationErrors = (state: LoadedCsv | SubmittingChanges | ErrorSubmittingChanges): boolean =>
  !!state.validationErrors?.entityValidationErrors &&
  state.validationErrors?.entityValidationErrors.some(
    (rowErrors) => rowErrors != null && rowErrors.some((it) => it != null && it.length > 0)
  )

export const hasTypeValidationErrors = (state: LoadedCsv | SubmittingChanges): boolean =>
  (!!state.validationErrors?.primaryLabelValidationErrors &&
    state.validationErrors.primaryLabelValidationErrors.length > 0) ||
  (!!state.validationErrors?.secondaryLabelValidationErrors &&
    state.validationErrors.secondaryLabelValidationErrors.length > 0) ||
  (!!state.validationErrors?.nameValidationErrors && state.validationErrors.nameValidationErrors.length > 0) ||
  (!!state.validationErrors?.fieldValidationErrors &&
    state.validationErrors.fieldValidationErrors.length > 0 &&
    state.validationErrors.fieldValidationErrors.some((it) => it != null && it.length > 0))

export default PageState
