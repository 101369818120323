import FormConfiguration from '../../domain/form/FormConfiguration'
import apiBaseUrl from './baseUrl'
import bearerTokenHeader from '../authorization/bearerTokenHeader'
import AuthenticationContext from '../authorization/AuthenticationContext'

type PutFormConfigurationParameters = {
  formConfiguration: FormConfiguration
  authContext: AuthenticationContext
}

const putFormConfiguration = async ({ formConfiguration, authContext }: PutFormConfigurationParameters) => {
  const response = await fetch(
    `${apiBaseUrl}/organizations/${authContext.organizationId}/forms/${formConfiguration.id}`,
    {
      method: 'PUT',
      headers: bearerTokenHeader(authContext.accessToken),
      body: JSON.stringify({ configuration: formConfiguration }),
    }
  )
  if (response.status !== 200)
    throw Error(`Unexpected response code ${response.status} from putFormConfiguration endpoint`)
}

export default putFormConfiguration
