import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId

type GetEntityTypeParameters = { id: EntityTypeId; authContext: AuthenticationContext }

const getEntityType = ({ id, authContext }: GetEntityTypeParameters): Promise<EntityType> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/types/${id.asString()}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
  }).then(async (response) => {
    if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from getEntityType endpoint`)
    return EntityType.fromJsonString(await response.text())
  })

export default getEntityType
