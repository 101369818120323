import { com } from '@eidu/entity'
import { Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import hasFieldError from '../../domain/entity/hasFieldError'
import DraftFieldValue from '../../domain/entity/DraftFieldValue'
import EntityFormReferenceField from './EntityFormReferenceField'
import EntityWithLabelAndRelated from '../../domain/entity/EntityWithLabelAndRelated'
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import Field = com.eidu.sharedlib.entity.field.Field
import FieldType = com.eidu.sharedlib.entity.field.FieldType
import FieldId = com.eidu.sharedlib.entity.field.FieldId

type EntityFormFieldProps = {
  field: Field
  fieldValue: DraftFieldValue | undefined
  error: boolean
  referenceableEntityTypesById: ReadonlyMap<EntityTypeId, EntityType>
  referenceableEntitiesByTypeId: ReadonlyMap<EntityTypeId, ReadonlyMap<EntityId, EntityWithLabelAndRelated>>
  updateField: (value: DraftFieldValue) => void
  setSearchQuery: (query: string) => void
}

const EntityFormField = ({
  field,
  fieldValue,
  error,
  referenceableEntityTypesById,
  referenceableEntitiesByTypeId,
  updateField,
  setSearchQuery,
}: EntityFormFieldProps) => {
  const updateValue = (event: { target: { value: string } }) =>
    updateField({ fieldId: field.id, type: field.type, value: event.target.value })

  switch (field.type) {
    case FieldType.Text:
      return (
        <TextField
          key={`field-${field.id.asString()}`}
          required={field.required}
          label={field.name}
          value={fieldValue?.value ?? ''}
          onChange={updateValue}
          error={error}
        />
      )
    case FieldType.Number:
      return (
        <>
          <TextField
            key={`field-${field.id.asString()}`}
            required={field.required}
            label={field.name}
            value={fieldValue?.value ?? ''}
            onChange={updateValue}
            error={error}
          />
          {fieldValue?.value && Number.isNaN(parseFloat(fieldValue.value)) && (
            <Typography>Please enter a valid number</Typography>
          )}
        </>
      )
    case FieldType.Reference:
      return (
        <EntityFormReferenceField
          field={field}
          fieldValue={fieldValue}
          referenceableEntityTypesById={referenceableEntityTypesById}
          referenceableEntitiesByTypeId={referenceableEntitiesByTypeId}
          updateField={updateField}
          error={error}
          setSearchQuery={setSearchQuery}
        />
      )
    default:
      return (
        <Typography key={`field-${field.id.asString()}`}>
          Field &quot;{field.name}&quot; has unsupported type {field.type.name}
        </Typography>
      )
  }
}

export type EntityFormProps = {
  fieldsById: ReadonlyMap<FieldId, DraftFieldValue>
  updateField: (value: DraftFieldValue) => void
  entityType: EntityType
  referenceableEntityTypesById: ReadonlyMap<EntityTypeId, EntityType>
  referenceableEntitiesByTypeId: ReadonlyMap<EntityTypeId, ReadonlyMap<EntityId, EntityWithLabelAndRelated>>
  setSearchQuery: (query: string) => void
}

const EntityForm = ({
  fieldsById,
  updateField,
  entityType,
  referenceableEntityTypesById,
  referenceableEntitiesByTypeId,
  setSearchQuery,
}: EntityFormProps) => (
  <Stack padding={0} spacing={3}>
    {entityType.fields.asJsReadonlyArrayView().map((field) => {
      const fieldValue = fieldsById.get(field.id) ?? undefined
      return (
        <EntityFormField
          key={`entity-form-field-${field.id.asString()}`}
          field={field}
          fieldValue={fieldsById.get(field.id)}
          referenceableEntityTypesById={referenceableEntityTypesById}
          referenceableEntitiesByTypeId={referenceableEntitiesByTypeId}
          updateField={updateField}
          error={hasFieldError(field, fieldValue, referenceableEntitiesByTypeId)}
          setSearchQuery={setSearchQuery}
        />
      )
    })}
  </Stack>
)

export default EntityForm
export { EntityFormField }
