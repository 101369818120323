import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import EntityId = com.eidu.sharedlib.entity.EntityId
import AuthenticationContext from '../../authorization/AuthenticationContext'

type InviteAuthUserParameters = {
  entityId: EntityId
  emailOrPhoneNumber: string
  authContext: AuthenticationContext
}

export enum InviteAuthUserResult {
  Success,
  InvalidEmailOrPhoneNumber,
  UserUnavailable,
}

const inviteAuthUser = async ({ entityId, emailOrPhoneNumber, authContext }: InviteAuthUserParameters) => {
  const response = await fetch(
    `${apiBaseUrl}/organizations/${authContext.organizationId}/entities/${entityId.asString()}/user/invite`,
    {
      headers: { Authorization: `Bearer ${authContext.accessToken}` },
      body: JSON.stringify({ emailOrPhoneNumber }),
      method: 'POST',
    }
  )
  if (response.status === 200) return InviteAuthUserResult.Success
  else if (response.status === 400) return InviteAuthUserResult.InvalidEmailOrPhoneNumber
  else if (response.status === 409) return InviteAuthUserResult.UserUnavailable
  else throw Error(`Unexpected response code ${response.status} when inviting user for entity ${entityId.asString()}`)
}

export default inviteAuthUser
