import { Backdrop, CircularProgress } from '@mui/material'

export type LoadingOverlayProps = { isOpen: boolean }

const LoadingOverlay = ({ isOpen }: LoadingOverlayProps) => (
  <Backdrop className="loadingOverlay" sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }} open={isOpen}>
    <CircularProgress color="inherit" />
  </Backdrop>
)

export default LoadingOverlay
