import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import requireValidPaginationParameters from '../common/requireValidPaginationParameters'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import PaginatedResponse = com.eidu.api.PaginatedResponse
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import entityTypePaginatedResponseFromJsonString = com.eidu.sharedlib.entity.api.types.entityTypePaginatedResponseFromJsonString

type GetEntityTypesParameters = { pageIndex: number; pageSize: number; authContext: AuthenticationContext }

const getEntityTypes = ({
  pageIndex,
  pageSize,
  authContext,
}: GetEntityTypesParameters): Promise<PaginatedResponse<EntityType>> => {
  requireValidPaginationParameters(pageIndex, pageSize)

  return fetch(
    `${apiBaseUrl}/organizations/${authContext.organizationId}/types?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      headers: { Authorization: `Bearer ${authContext.accessToken}` },
    }
  ).then(async (response) => {
    if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from getEntityTypes endpoint`)
    return entityTypePaginatedResponseFromJsonString(await response.text())
  })
}

export default getEntityTypes
