import { createContext, FC, ReactNode, useContext, useEffect } from 'react'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import EntityTypeRepository, { setGlobalToken } from '../EntityTypeRepository'

const repository = new EntityTypeRepository()

export const EntityTypesContext = createContext<EntityTypeRepository>(repository)

interface EntityTypesProviderProps {
  children: ReactNode
}

export const EntityTypesProvider: FC<EntityTypesProviderProps> = ({ children }) => {
  const authContext = useContext<IAuthContext>(AuthContext)

  useEffect(() => {
    setGlobalToken(authContext)
  }, [authContext])

  return <EntityTypesContext.Provider value={repository}>{children}</EntityTypesContext.Provider>
}
