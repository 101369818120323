import { Autocomplete, MenuItem, TextField } from '@mui/material'
import React from 'react'
import { com } from '@eidu/entity'
import DraftFieldValue from '../../domain/entity/DraftFieldValue'
import EqualityHashMap from '../../util/EqualityHashMap'
import EntityReference from './EntityReference'
import EntityWithLabelAndRelated from '../../domain/entity/EntityWithLabelAndRelated'
import Field = com.eidu.sharedlib.entity.field.Field
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityId = com.eidu.sharedlib.entity.EntityId
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import FieldType = com.eidu.sharedlib.entity.field.FieldType
import entityIdFromStringOrNull = com.eidu.sharedlib.entity.entityIdFromStringOrNull
import entityIdFromString = com.eidu.sharedlib.entity.entityIdFromString

export type EntityFormReferenceFieldProps = {
  field: Field
  fieldValue: DraftFieldValue | undefined
  referenceableEntityTypesById: ReadonlyMap<EntityTypeId, EntityType>
  referenceableEntitiesByTypeId: ReadonlyMap<EntityTypeId, ReadonlyMap<EntityId, EntityWithLabelAndRelated>>
  updateField: (value: DraftFieldValue) => void
  error: boolean
  setSearchQuery: (query: string) => void
}

const EntityFormReferenceField = ({
  field,
  fieldValue,
  referenceableEntitiesByTypeId,
  referenceableEntityTypesById,
  updateField,
  error,
  setSearchQuery,
}: EntityFormReferenceFieldProps) => {
  const referenceableEntitiesById = new EqualityHashMap(
    (field.validReferenceTypes
      ? [...referenceableEntitiesByTypeId.entries()].flatMap(([typeId, entities]) =>
          field.validReferenceTypes?.asJsReadonlySetView()?.has(typeId) ? [entities] : []
        )
      : [...referenceableEntitiesByTypeId.values()]
    ).flatMap((entityById) => [...entityById.entries()])
  )

  const getEntityId = (value: DraftFieldValue | undefined) => value?.value?.let((it) => entityIdFromStringOrNull(it))

  const updateReferenceField = (fieldId: FieldId, type: FieldType, value: EntityId | null | undefined): void => {
    updateField({ fieldId, type, value: value?.asString() ?? '' })
  }

  const options = [...referenceableEntitiesById.keys()].map((it) => it.asString())

  return (
    options && (
      <Autocomplete
        key={`field-${field.id.asString()}`}
        disablePortal
        options={options}
        getOptionLabel={(entityId) => {
          const entity = referenceableEntitiesById.get(entityIdFromString(entityId))
          return entity?.primaryLabelText ?? ''
        }}
        value={getEntityId(fieldValue)?.asString() || null}
        onChange={(_, value) =>
          updateReferenceField(field.id, field.type, value != null ? entityIdFromString(value) : null)
        }
        noOptionsText="No entities"
        onInputChange={(_, query) => {
          setSearchQuery(query)
        }}
        filterOptions={(x) => x}
        renderInput={(params) => <TextField {...params} required={field.required} label={field.name} error={error} />}
        renderOption={(props, entityId) => {
          const entity = referenceableEntitiesById.get(entityIdFromString(entityId))
          const referencedEntityType = entity && referenceableEntityTypesById.get(entity.entity.typeId)
          return (
            entity && (
              <MenuItem {...props} key={`menu-item-${entity.entity.id.asString()}`}>
                <EntityReference
                  id={entityIdFromString(entityId)}
                  typeId={entity.entity.typeId}
                  label={entity.primaryLabelText}
                  secondaryLabel={entity.secondaryLabelText}
                  labelAsLink={false}
                  typeName={referencedEntityType?.name}
                />
              </MenuItem>
            )
          )
        }}
      />
    )
  )
}

export default EntityFormReferenceField
