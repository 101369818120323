import apiBaseUrl from './baseUrl'
import FormConfiguration from '../../domain/form/FormConfiguration'
import bearerTokenHeader from '../authorization/bearerTokenHeader'
import AuthenticationContext from '../authorization/AuthenticationContext'

type GetFormConfigurationParameters = { formId: string; authContext: AuthenticationContext }

const getFormConfiguration = ({ formId, authContext }: GetFormConfigurationParameters): Promise<FormConfiguration> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/forms/${formId}`, {
    headers: bearerTokenHeader(authContext.accessToken),
  }).then(async (it) => {
    const response = await it.json()
    if (it.status === 200) return response.configuration
    else throw Error(`Unexpected response code ${it.status} from getFormConfiguration endpoint`)
  })

export default getFormConfiguration
