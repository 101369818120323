import { com } from '@eidu/entity'
import { IAuthContext } from 'react-oauth2-code-pkce'
import BatchCache from './BatchCache'
import PageCache, { Page } from './PageCache'
import getEntityType from '../api/entity/type/getEntityType'
import getEntityTypes from '../api/entity/type/getEntityTypes'
import sortedBy from '../util/sort/sortedBy'
import patchEntityType, { PatchEntityTypeParameters } from '../api/entity/type/patchEntityType'
import postEntityType, { PostEntityTypeParameters } from '../api/entity/type/postEntityType'
import EqualityHashMap from '../util/EqualityHashMap'
import { requireNotUndefinedOrNull } from '../util/require'
import AuthenticationContext, { createAuthContext } from '../api/authorization/AuthenticationContext'
import deleteEntityType, { DeleteEntityTypeParameters } from '../api/entity/type/deleteEntityType'
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityLabel = com.eidu.sharedlib.entity.label.EntityLabel
import Field = com.eidu.sharedlib.entity.field.Field
import PatchTypeResponse = com.eidu.sharedlib.entity.api.types.PatchTypeResponse
import EntityTypeKind = com.eidu.sharedlib.entity.type.EntityTypeKind

const PAGE_SIZE = 500

let globalAuthContext: AuthenticationContext | undefined

export function setGlobalToken(authContext: IAuthContext) {
  globalAuthContext = createAuthContext(authContext)
}

type EntityTypeRepositoryParams = {
  requestFetchEntityType?: (id: EntityTypeId) => Promise<EntityType>
  requestFetchEntityTypesPage?: (pageIndex: number, pageSize: number) => Promise<Page<EntityTypeId, EntityType>>
  requestCreateEntityType?: (params: PostEntityTypeParameters) => Promise<EntityType>
  requestModifyEntityType?: (params: PatchEntityTypeParameters) => Promise<PatchTypeResponse>
  requestDeleteEntityType?: (params: DeleteEntityTypeParameters) => Promise<void>
}

class EntityTypeRepository extends PageCache<EntityTypeId, EntityType> {
  constructor({
    requestFetchEntityType,
    requestFetchEntityTypesPage,
    requestCreateEntityType,
    requestModifyEntityType,
    requestDeleteEntityType,
  }: EntityTypeRepositoryParams = {}) {
    super(
      new BatchCache(requestFetchEntityType || EntityTypeRepository.fetchEntityTypeDefault),
      requestFetchEntityTypesPage || EntityTypeRepository.fetchEntityTypesPageDefault,
      PAGE_SIZE
    )
    this.doCreateEntityType = requestCreateEntityType || EntityTypeRepository.createEntityTypeDefault
    this.doModifyEntityType = requestModifyEntityType || EntityTypeRepository.modifyEntityTypeDefault
    this.doRemoveEntityType = requestDeleteEntityType || EntityTypeRepository.deleteEntityTypeDefault
  }

  private readonly doCreateEntityType: (params: PostEntityTypeParameters) => Promise<EntityType>

  private readonly doModifyEntityType: (params: PatchEntityTypeParameters) => Promise<PatchTypeResponse>

  private readonly doRemoveEntityType: (params: DeleteEntityTypeParameters) => Promise<void>

  useSortedEntityTypes = () =>
    this.useAll()?.let((it) => sortedBy(Array.from(it.entries()), ([, value]) => value.name).map(([, value]) => value))

  createEntityType = async (
    name: string,
    fieldsToCreate: readonly Field[],
    label: EntityLabel,
    kind: EntityTypeKind
  ): Promise<EntityType> => {
    const response = await this.doCreateEntityType({
      name,
      fieldsToCreate,
      label,
      kind,
      authContext: requireNotUndefinedOrNull(globalAuthContext),
    })
    await this.refreshAll()
    return response
  }

  modifyEntityType = async (id: EntityTypeId, fieldsToCreate: readonly Field[]): Promise<PatchTypeResponse> => {
    const response = await this.doModifyEntityType({
      id,
      fieldsToCreate,
      authContext: requireNotUndefinedOrNull(globalAuthContext),
    })
    await this.refreshAll()
    return response
  }

  removeEntityType = async (id: EntityTypeId): Promise<void> => {
    await this.doRemoveEntityType({
      id,
      authContext: requireNotUndefinedOrNull(globalAuthContext),
    })
    await this.refreshAll()
  }

  private static fetchEntityTypeDefault = (id: EntityTypeId) =>
    getEntityType({ id, authContext: requireNotUndefinedOrNull(globalAuthContext) })

  private static fetchEntityTypesPageDefault = (pageIndex: number, pageSize: number) =>
    getEntityTypes({ pageIndex, pageSize, authContext: requireNotUndefinedOrNull(globalAuthContext) }).then(
      (response) => ({
        entries: new EqualityHashMap(
          response.documents.asJsReadonlyArrayView().map((it): [EntityTypeId, EntityType] => [it.id, it])
        ),
        totalCount: response.totalCount,
      })
    )

  private static createEntityTypeDefault = async (params: PostEntityTypeParameters): Promise<EntityType> =>
    postEntityType(params)

  private static modifyEntityTypeDefault = async (params: PatchEntityTypeParameters): Promise<PatchTypeResponse> =>
    patchEntityType(params)

  private static deleteEntityTypeDefault = async (params: DeleteEntityTypeParameters): Promise<void> =>
    deleteEntityType(params)
}

export default EntityTypeRepository
