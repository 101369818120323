import apiBaseUrl from './baseUrl'
import bearerTokenHeader from '../authorization/bearerTokenHeader'
import FormConfigurationToCreate from '../../domain/form/FormConfigurationToCreate'
import AuthenticationContext from '../authorization/AuthenticationContext'

type PostFormConfigurationParameters = {
  formConfigurationToCreate: FormConfigurationToCreate
  authContext: AuthenticationContext
}

const postFormConfiguration = async ({ formConfigurationToCreate, authContext }: PostFormConfigurationParameters) => {
  const response = await fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/forms`, {
    method: 'POST',
    headers: bearerTokenHeader(authContext.accessToken),
    body: JSON.stringify({ configuration: formConfigurationToCreate }),
  })
  if (response.status !== 200)
    throw Error(`Unexpected response code ${response.status} from postFormConfiguration endpoint`)
}

export default postFormConfiguration
