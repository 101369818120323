import supportStaffServiceBaseUrl from '../supportStaffServiceBaseUrl'
import Program from '../../domain/user/Program'
import bearerTokenHeader from '../authorization/bearerTokenHeader'
import AuthenticationContext from '../authorization/AuthenticationContext'

type GetProgramsParameters = {
  authContext: AuthenticationContext
}

type GetProgramsResponse = { programs: readonly Program[] }

const getPrograms = ({ authContext }: GetProgramsParameters): Promise<readonly Program[]> =>
  fetch(`${supportStaffServiceBaseUrl}/organizations/${authContext.organizationId}/programs`, {
    headers: bearerTokenHeader(authContext.accessToken),
  }).then(async (it) => {
    if (it.status === 200) {
      const response: GetProgramsResponse = await it.json()
      return response.programs
    } else throw Error(`Unexpected response code ${it.status} from getPrograms endpoint`)
  })

export default getPrograms
