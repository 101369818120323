import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import EntityId = com.eidu.sharedlib.entity.EntityId

type DeleteEntityParameters = { id: EntityId; authContext: AuthenticationContext }

const deleteEntity = async ({ id, authContext }: DeleteEntityParameters) => {
  const response = await fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/entities/${id.asString()}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
    method: 'DELETE',
  })
  if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from deleteEntity endpoint`)
}

export default deleteEntity
