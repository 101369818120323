import { com } from '@eidu/entity'
import React from 'react'
import MultiSelect from '../MultiSelect'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId

export type EntityTypeInputProps = {
  availableEntityTypes: readonly EntityType[]
  selectedEntityTypes: readonly EntityTypeId[]
  setSelectedEntityTypes: (selectedEntityTypes: readonly EntityTypeId[]) => void
  label: string
  labelId: string
  disabled?: boolean
  error?: boolean
  sx?: React.CSSProperties
}

const EntityTypeInput = ({
  availableEntityTypes,
  selectedEntityTypes,
  setSelectedEntityTypes,
  label,
  labelId,
  disabled,
  error,
  sx,
}: EntityTypeInputProps) => (
  <MultiSelect
    available={availableEntityTypes.map((it) => ({ [it.name]: it.id }))}
    selected={selectedEntityTypes}
    setSelected={setSelectedEntityTypes}
    equals={(value, other) => value.asString() === other.asString()}
    label={label}
    labelId={labelId}
    disabled={disabled}
    error={error}
    sx={sx}
  />
)

export default EntityTypeInput
