import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import JobStatus = com.eidu.sharedlib.entity.job.JobStatus
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId

type ExportEntitiesOfTypeParameters = { id: EntityTypeId; authContext: AuthenticationContext }

const exportEntitiesOfType = ({ id, authContext }: ExportEntitiesOfTypeParameters): Promise<string> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/types/${id.asString()}/entities/export`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
  }).then(async (response): Promise<string> => {
    if (response.status !== 200)
      throw Error(`Unexpected response code ${response.status} from entities export endpoint`)

    return JobStatus.fromJsonString(await response.text()).jobId
  })

export default exportEntitiesOfType
