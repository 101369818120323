import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import requireValidPaginationParameters from '../common/requireValidPaginationParameters'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import Entity = com.eidu.sharedlib.entity.Entity
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import PaginatedResponseWithRelated = com.eidu.sharedlib.entity.api.common.PaginatedResponseWithRelated
import entityPaginatedResponseWithRelatedFromJsonString = com.eidu.sharedlib.entity.api.entities.entityPaginatedResponseWithRelatedFromJsonString

type SearchEntitiesOfTypeParameters = {
  ids: readonly EntityTypeId[]
  withRelatedEntities?: boolean
  searchQuery: string
  pageIndex: number
  pageSize: number
  authContext: AuthenticationContext
}

const searchEntitiesOfType = ({
  ids,
  withRelatedEntities,
  searchQuery,
  pageIndex,
  pageSize,
  authContext,
}: SearchEntitiesOfTypeParameters): Promise<PaginatedResponseWithRelated<Entity>> => {
  requireValidPaginationParameters(pageIndex, pageSize)

  const related = withRelatedEntities ? 'true' : 'false'

  return fetch(
    `${apiBaseUrl}/organizations/${authContext.organizationId}/search-entities?related=${related}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      headers: { Authorization: `Bearer ${authContext.accessToken}` },
      method: 'POST',
      body: JSON.stringify({
        query: searchQuery,
        entityTypeIds: ids.map((id) => id.asString()),
      }),
    }
  ).then(async (response) => {
    if (response.status !== 200)
      throw Error(`Unexpected response code ${response.status} from searchEntitiesOfType endpoint`)

    return entityPaginatedResponseWithRelatedFromJsonString(await response.text())
  })
}

export default searchEntitiesOfType
