import { IAuthContext } from 'react-oauth2-code-pkce'

type AuthenticationContext = {
  accessToken: string
  organizationId: string
}

export const createAuthContext = (context: IAuthContext): AuthenticationContext | undefined =>
  context.idTokenData?.organization_id && {
    accessToken: context.token,
    organizationId: context.idTokenData.organization_id,
  }

export default AuthenticationContext
