import { com, kotlin, Nullable } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import serializeToJsonString = com.eidu.sharedlib.entity.util.serializeToJsonString
import PatchEntitiesRequest = com.eidu.sharedlib.entity.api.entities.PatchEntitiesRequest
import EntityId = com.eidu.sharedlib.entity.EntityId
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import KtMap = kotlin.collections.KtMap
import PatchEntityRequest = com.eidu.sharedlib.entity.api.entities.PatchEntityRequest
import AuthenticationContext from '../../authorization/AuthenticationContext'

type PatchEntitiesParameters = {
  valuesByFieldIdByEntityId: Map<EntityId, Map<FieldId, Nullable<FieldValue>>>
  authContext: AuthenticationContext
}

const patchEntities = async ({ valuesByFieldIdByEntityId, authContext }: PatchEntitiesParameters) => {
  if (valuesByFieldIdByEntityId.size === 0) return

  const response = await fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/entities`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
    body: serializeToJsonString(
      new PatchEntitiesRequest(
        KtMap.fromJsMap(
          new Map(
            [...valuesByFieldIdByEntityId].map(([id, valuesByFieldId]) => [
              id,
              new PatchEntityRequest(KtMap.fromJsMap(valuesByFieldId)),
            ])
          )
        )
      )
    ),
    method: 'PATCH',
  })
  if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from patchEntities endpoint`)
}

export default patchEntities
