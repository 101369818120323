import { Alert, Box, FormControlLabel, Stack, Switch, TextField, Typography, useTheme } from '@mui/material'
import ReactCodeMirror from '@uiw/react-codemirror'
import { OpenInNew } from '@mui/icons-material'
import { yaml } from '@codemirror/lang-yaml'
import { createTheme } from '@uiw/codemirror-themes'
import FormAvailabilityRow from './availability/FormAvailabilityRow'
import defaultAvailability from '../../domain/form/availability/defaultAvailability'
import { Availability } from '../../domain/form/availability/Availability'
import Program from '../../domain/user/Program'
import { hasTitleError } from '../../domain/form/hasConfigurationErrors'

export type FormConfigurationFormProps = {
  title: string
  availabilities: readonly Availability[]
  programs: readonly Program[]
  organizationId: string
  useYamlFormContent: boolean
  oldFormContent?: string | undefined
  formContent: string | undefined
  formContentErrorMessage: string | undefined
  onTitleChange: (title: string) => void
  onAvailabilitiesChange: (availabilities: readonly Availability[]) => void
  onUseYamlFormContentChange: (useYamlFormContent: boolean) => void
  onFormContentChange: (content: string) => void
}

const FormConfigurationForm = ({
  title,
  availabilities,
  programs,
  organizationId,
  useYamlFormContent,
  oldFormContent,
  formContent,
  formContentErrorMessage,
  onTitleChange,
  onAvailabilitiesChange,
  onUseYamlFormContentChange,
  onFormContentChange,
}: FormConfigurationFormProps) => {
  const theme = useTheme()

  const codemirrorTheme = createTheme({
    theme: 'light',
    settings: {
      background: '#ffffff',
      selection: theme.palette.primary.light,
      selectionMatch: theme.palette.secondary.light,
      lineHighlight: theme.palette.primary.semiTransparent,
    },
    styles: [],
  })

  return (
    <>
      <TextField
        required
        label="Title"
        value={title}
        onChange={(event) => onTitleChange(event.target.value)}
        error={hasTitleError(title)}
      />
      {availabilities.map((availability, index) => (
        <FormAvailabilityRow
          availability={availability}
          readOnly={!index}
          idPrefix={`form-configuration-availability-${index}`}
          programs={programs}
          organizationId={organizationId}
          onAdd={() => onAvailabilitiesChange(availabilities.toSpliced(index + 1, 0, defaultAvailability))}
          onChange={(updatedAvailability) =>
            onAvailabilitiesChange(availabilities.toSpliced(index, 1, updatedAvailability))
          }
          onDelete={() => onAvailabilitiesChange(availabilities.toSpliced(index, 1))}
        />
      ))}
      <Stack direction="row" alignItems="center" spacing="16px">
        <FormControlLabel
          control={
            <Switch
              checked={useYamlFormContent}
              disabled={formContent !== oldFormContent}
              onChange={(event) => {
                if (formContent === oldFormContent) onUseYamlFormContentChange(event.target.checked)
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Use new YAML format for form content"
        />
        {!useYamlFormContent && (
          <a
            href="https://docs.google.com/document/d/1tjoP6oc3HBOAbbWXqwS_v-A_VbNoqwvkm2BOrkXoh2E/edit#heading=h.kk1966kbedef"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Stack direction="row" alignItems="center" spacing="4px">
              <Typography>View documentation on the form definition language</Typography>
              <OpenInNew fontSize="small" />
            </Stack>
          </a>
        )}
      </Stack>
      <Stack
        sx={{ width: '100%', maxWidth: '100%', flexShrink: 1, boxSizing: 'border-box' }}
        direction="row"
        alignItems="start"
        spacing="16px"
      >
        <Stack sx={{ flex: 1, minWidth: 0, boxSizing: 'border-box', overflow: 'hidden' }}>
          <Typography variant="h6">New form content</Typography>
          <ReactCodeMirror
            placeholder="Form content"
            value={formContent}
            extensions={useYamlFormContent ? [yaml()] : []}
            onChange={(content) => onFormContentChange(content)}
            theme={codemirrorTheme}
          />
        </Stack>
        {oldFormContent && (
          <Stack sx={{ flex: 1, minWidth: 0, boxSizing: 'border-box', overflow: 'hidden' }}>
            <Typography variant="h6">Current form content</Typography>
            <Box sx={{ opacity: 0.6 }}>
              <ReactCodeMirror
                placeholder="Form content"
                value={oldFormContent}
                onChange={() => {}}
                theme={codemirrorTheme}
                editable={false}
                readOnly
              />
            </Box>
          </Stack>
        )}
      </Stack>
      {formContentErrorMessage && <Alert severity="error">{formContentErrorMessage}</Alert>}
    </>
  )
}

export default FormConfigurationForm
