import apiBaseUrl from './baseUrl'
import bearerTokenHeader from '../authorization/bearerTokenHeader'
import AuthenticationContext from '../authorization/AuthenticationContext'

type GetFormContentTextParameters = {
  formContentId: string
  useYamlFormat: boolean
  authContext: AuthenticationContext
}

const getFormContentText = ({
  formContentId,
  useYamlFormat,
  authContext,
}: GetFormContentTextParameters): Promise<string> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/form-contents-text/${formContentId}`, {
    headers: useYamlFormat
      ? { ...bearerTokenHeader(authContext.accessToken), Accept: 'application/yaml' }
      : bearerTokenHeader(authContext.accessToken),
  }).then(async (it) => {
    const response = await it.text()
    if (it.status === 200) return response
    else throw Error(`Unexpected response code ${it.status} from getFormConfiguration endpoint`)
  })

export default getFormContentText
