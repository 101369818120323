import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import JobStatus = com.eidu.sharedlib.entity.job.JobStatus

type GetJobStatusParameters = {
  jobId: string
  authContext: AuthenticationContext
}

const getJobStatus = ({ jobId, authContext }: GetJobStatusParameters): Promise<JobStatus> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/job-status/${jobId}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
  }).then(async (response): Promise<JobStatus> => {
    if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from job status endpoint`)

    return JobStatus.fromJsonString(await response.text())
  })

export default getJobStatus
