import { com } from '@eidu/entity'
import Outcome = com.eidu.sharedlib.util.Outcome
import Success = com.eidu.sharedlib.util.Success
import Failure = com.eidu.sharedlib.util.Failure

const requireSuccess = <T>(value: Outcome<unknown, T>): T => {
  if (value instanceof Success) return (value as Success<T>).value
  else throw new Error(`Expected success but got error: ${(value as Failure<unknown>).value}`)
}

export default requireSuccess
