import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Box, Button, Collapse, Stack } from '@mui/material'
import Papa from 'papaparse'
import { com, kotlin } from '@eidu/entity'
import { groupBy } from 'lodash'
import { useGridApiRef } from '@mui/x-data-grid'
import LoadingOverlay from '../../components/LoadingOverlay'
import { useSnackBar } from '../../components/SnackBarProvider'
import extractFieldsAndData from '../../domain/entity/extractFieldsAndData'
import FieldData, {
  ExistingFieldData,
  fieldDataFromExistingField,
  fieldDataFromNewField,
  NewFieldData,
} from '../../domain/entity/FieldData'
import FieldTypeInput from '../../components/entity/FieldTypeInput'
import UploadEntityDataGrid from '../../components/entity/UploadEntityDataGrid'
import createValidatedFieldValues from '../../domain/entity/createValidatedFieldValues'
import pageSizeOptions from '../../util/pagination/pageSizeOptions'
import PaginationModel from '../../util/pagination/PaginationModel'
import EntityTypeRepository from '../../io/EntityTypeRepository'
import { isNotUndefinedOrNull } from '../../util/predicates'
import UploadPageState, {
  AwaitingCsvUpload,
  awaitingCsvUpload,
  errorLoadingCsv,
  ErrorLoadingCsv,
  errorLoadingFieldType,
  fetchingEntityTypeDefinition,
  hasEntityValidationErrors,
  hasTypeValidationErrors,
  loadedCsv,
  LoadedCsv,
  SubmittingChanges,
  withEntityValidationErrors,
  withLabelUpdated,
  withSubmissionError,
  withSubmitting,
  withUpdatedEntityValidations,
} from './UploadPageState'
import {
  withUpdatedLabelValidation,
  withUpdatedNameValidation,
  withUpdatedTypeValidations,
} from './UploadPageValidation'
import { createEntityLabel, labelLineToString } from '../../components/entity/label/LabelUtil'
import FileInput from '../../components/FileInput'
import useEntityRepository from '../../io/useEntityRepository'
import EntityWithLabel from '../../domain/entity/EntityWithLabel'
import sortedBy from '../../util/sort/sortedBy'
import { logException } from '../../util/Logging'
import EntityLabel = com.eidu.sharedlib.entity.label.EntityLabel
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeKind = com.eidu.sharedlib.entity.type.EntityTypeKind
import EntityTypeValidator = com.eidu.sharedlib.entity.validation.EntityTypeValidator
import Field = com.eidu.sharedlib.entity.field.Field
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import FieldType = com.eidu.sharedlib.entity.field.FieldType
import entityIdFromStringOrNull = com.eidu.sharedlib.entity.entityIdFromStringOrNull
import EntityToCreate = com.eidu.sharedlib.entity.api.entities.EntityToCreate
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import KtMap = kotlin.collections.KtMap
import KtList = kotlin.collections.KtList
import Success = com.eidu.sharedlib.util.Success
import Failure = com.eidu.sharedlib.util.Failure

type PopulatedExistingFieldData = ExistingFieldData & { columnIndex: number }

const isPopulated = (field: FieldData): field is PopulatedExistingFieldData | NewFieldData =>
  field.columnIndex !== undefined

const canSubmit = (state: UploadPageState): boolean =>
  !!state.data &&
  !state.submitting &&
  !state.error &&
  !hasEntityValidationErrors(state) &&
  !hasTypeValidationErrors(state)

type UploadPageContentProps = {
  state: UploadPageState
  entityTypeId: EntityTypeId | undefined
  setEntityTypeName: (value: string) => void
  setFields: (fieldTypes: readonly FieldData[]) => Promise<void>
  setPrimaryLabel: (label?: string) => void
  setSecondaryLabel: (label?: string) => void
  setKind: (kind: EntityTypeKind) => void
  processUploadedFile: (file: File) => void
  submitEntities: () => void
  relatedEntities: ReadonlyMap<EntityId, EntityWithLabel | null>
  paginationModel: PaginationModel
  setPaginationModel: (paginationModel: PaginationModel) => void
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
  indexBeingUpdated: number | null
  setIndexBeingUpdated: (index: number | null) => void
  deleteType: (entityTypeId: EntityTypeId) => void
}

const UploadPageContent = ({
  state,
  entityTypeId,
  setEntityTypeName,
  setFields,
  setPrimaryLabel,
  setSecondaryLabel,
  setKind,
  processUploadedFile,
  submitEntities,
  relatedEntities,
  paginationModel,
  setPaginationModel,
  entityTypes,
  indexBeingUpdated,
  setIndexBeingUpdated,
  deleteType,
}: UploadPageContentProps) => {
  const apiRef = useGridApiRef()
  useEffect(() => {
    if (state?.data && apiRef && apiRef.current && apiRef.current.autosizeColumns) {
      setTimeout(() => {
        apiRef.current
          .autosizeColumns({
            includeHeaders: true,
            includeOutliers: true,
            outliersFactor: 1,
            expand: false,
          })
          .then()
      }, 0)
    }
  }, [
    JSON.stringify(state?.data),
    JSON.stringify(state?.fields),
    JSON.stringify(state?.existingFields),
    JSON.stringify(paginationModel),
    relatedEntities,
    entityTypes,
  ])

  const sortedTypes = useMemo(
    () => sortedBy(Array.from(entityTypes.entries()), ([, value]) => value.name).map(([, value]) => value),
    [entityTypes]
  )

  return (
    <Stack padding={3} spacing={2}>
      {state.fields && (
        <FieldTypeInput
          entityTypeId={entityTypeId}
          idPrefix="entity-type-input"
          entityTypeName={state.entityTypeName}
          entityTypeNameErrors={state.validationErrors?.nameValidationErrors}
          setEntityTypeName={setEntityTypeName}
          fields={state.fields}
          fieldErrors={state.validationErrors?.fieldValidationErrors}
          setFields={setFields}
          primaryLabel={state.primaryLabel || ''}
          setPrimaryLabel={setPrimaryLabel}
          secondaryLabel={state.secondaryLabel || ''}
          setSecondaryLabel={setSecondaryLabel}
          labelEditable={entityTypeId === undefined}
          primaryLabelErrors={state.validationErrors?.primaryLabelValidationErrors}
          secondaryLabelErrors={state.validationErrors?.secondaryLabelValidationErrors}
          kind={state.kind}
          setKind={setKind}
          availableEntityTypes={sortedTypes}
          indexBeingUpdated={indexBeingUpdated}
          setIndexBeingUpdated={setIndexBeingUpdated}
          deleteType={deleteType}
        />
      )}
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FileInput
          required
          accept=".csv"
          onChange={(file) => {
            if (file) processUploadedFile(file)
          }}
        />
        {state.data && (
          <Box>
            <Button
              disabled={!canSubmit(state)}
              variant="contained"
              sx={{ width: 'fit-content' }}
              onClick={submitEntities}
            >
              Submit entities
            </Button>
          </Box>
        )}
      </Stack>
      {state.data && (
        <>
          <Collapse in={hasEntityValidationErrors(state)}>
            <Alert variant="filled" severity="error" sx={{ margin: 2 }}>
              Found validation errors
            </Alert>
          </Collapse>
          {state.fields && (
            <UploadEntityDataGrid
              data={state.data}
              fields={state.fields}
              referencedEntities={relatedEntities}
              referencedTypes={entityTypes}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              validationErrors={state.validationErrors?.entityValidationErrors}
              apiRef={apiRef}
            />
          )}
        </>
      )}
    </Stack>
  )
}

const extractPopulatedFieldsInOrder = (fields: readonly FieldData[]): (PopulatedExistingFieldData | NewFieldData)[] =>
  fields.flatMap((field) => (isPopulated(field) ? [field] : [])).sort((a, b) => a.columnIndex - b.columnIndex)

const hasDuplicateField = (data: readonly (readonly string[])[]) =>
  Object.entries(groupBy(data[0], (field) => field)).some(([_, value]) => value.length > 1)

const getNewFieldsNames = (fields: readonly FieldData[]): string[] =>
  fields.filter((it) => it.field.id === undefined).map((it) => it.field.name)

const processFile = (
  isConfiguringEntityType: boolean,
  state: UploadPageState,
  setState: (newState: LoadedCsv | ErrorLoadingCsv) => void,
  file: File,
  getEntityTypeIds: (ids: EntityId[]) => Promise<ReadonlyMap<EntityId, EntityTypeId>>
) => {
  Papa.parse(file, {
    worker: true,
    skipEmptyLines: 'greedy',
    complete: async (result) => {
      if (state.existingFields === undefined || state.entityTypeName === undefined) {
        setState(errorLoadingCsv(state, 'Failed to load CSV file. Please refresh the page any try again.'))
        return
      }
      const allRows = result.data.filter((row) =>
        (row as readonly string[]).some((value) => value)
      ) as readonly (readonly string[])[]
      if (hasDuplicateField(allRows)) {
        setState(errorLoadingCsv(state, 'The CSV file has duplicate field names. Please correct the file.'))
        return
      }
      const { fieldData, data } = extractFieldsAndData(state.existingFields, allRows)

      const fields = fieldData.map((field) => field.field)
      if (
        fieldData.some((field) => EntityTypeValidator.validateField(field.field, KtList.fromJsArray(fields)) != null)
      ) {
        setState(errorLoadingCsv(state, 'The CSV file has invalid fields. Please correct the file.'))
        return
      }

      const newFieldsNames = getNewFieldsNames(fieldData)
      if (!isConfiguringEntityType && newFieldsNames.length > 0) {
        setState(
          errorLoadingCsv(
            state,
            'The CSV file contains unknown field names. Please correct the file. Unknown field names: ' +
              newFieldsNames.join(',')
          )
        )
        return
      }

      const primaryLabel = state.primaryLabel || fields.length > 0 ? `{${fields[0].name}}` : undefined
      const { secondaryLabel } = state
      const labelUpdated = state.labelUpdated || !state.primaryLabel

      setState(
        withUpdatedTypeValidations(
          await withUpdatedEntityValidations(
            loadedCsv(
              state.entityTypeName,
              state.existingFields,
              fieldData,
              primaryLabel,
              secondaryLabel,
              state.kind,
              labelUpdated,
              data,
              file
            ),
            getEntityTypeIds
          )
        )
      )
    },
    error: () => setState(errorLoadingCsv(state, 'Failed to load CSV. Please try again or check the CSV file.')),
  })
}

const prepareFieldsToCreate = (fields: readonly FieldData[]): Field[] =>
  fields.filter(({ editable }) => editable).map((it) => (it as NewFieldData).field)

const mergeCreatedFields = (fields: readonly FieldData[], createdFields: readonly Field[]) => {
  const existingFieldData: readonly ExistingFieldData[] = fields.flatMap((field) => (field.existing ? [field] : []))
  const newFieldData: readonly NewFieldData[] = createdFields.map((field) =>
    fieldDataFromNewField(field, fields.find((it) => it.field.name === field.name)?.columnIndex!)
  )
  return [...existingFieldData, ...newFieldData]
}

const prepareEntities = (
  entityTypeId: EntityTypeId,
  fieldValues: readonly Map<FieldId, FieldValue>[]
): EntityToCreate[] => fieldValues.map((it) => new EntityToCreate(entityTypeId, KtMap.fromJsMap(it)))

type ReadyUploadPageProps = {
  entityTypeId: EntityTypeId | undefined
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
  entityTypeRepository: EntityTypeRepository
  configure?: boolean
}

const ReadyUploadPage = ({
  entityTypeId,
  entityTypes,
  entityTypeRepository,
  configure = false,
}: ReadyUploadPageProps) => {
  const navigate = useNavigate()
  const entityRepository = useEntityRepository(
    {
      typeId: entityTypeId,
      types: entityTypes,
    },
    [entityTypeId?.asString()]
  )
  const [state, setState] = useState<UploadPageState>(fetchingEntityTypeDefinition())
  const [paginationModel, setPaginationModel] = useState({ pageSize: pageSizeOptions.small, page: 0 })
  const [fieldBeingUpdated, setFieldBeingUpdated] = useState<number | null>(null)
  const { showSnackBar } = useSnackBar()

  const init = () => {
    if (entityTypeId) {
      setState(fetchingEntityTypeDefinition())
      entityTypeRepository.get(entityTypeId).then(
        (entityType) => {
          const fields = entityType.fields.asJsReadonlyArrayView().map(fieldDataFromExistingField)
          setState(
            awaitingCsvUpload(
              entityType.name,
              fields,
              labelLineToString(entityType.label.primary, fields),
              entityType.label.secondary?.let((it) => labelLineToString(it, fields)) || '',
              entityType.kind
            )
          )
        },
        () => setState(errorLoadingFieldType('Failed to load fields. Please try again.'))
      )
    } else setState(awaitingCsvUpload('', []))
  }

  const submitType = async (
    entityTypeName: string | undefined,
    fieldsToCreate: readonly Field[],
    label: EntityLabel | undefined,
    kind: EntityTypeKind | undefined
  ): Promise<{ typeId: EntityTypeId; createdFields: readonly Field[] }> => {
    if (entityTypeId !== undefined) {
      // We don't support modifying labels at this point
      const result = await entityTypeRepository.modifyEntityType(entityTypeId, fieldsToCreate)
      return { typeId: result.id, createdFields: result.createdFields.asJsReadonlyArrayView() }
    } else if (entityTypeName && label && kind) {
      const result = await entityTypeRepository.createEntityType(entityTypeName, fieldsToCreate, label, kind)
      return { typeId: result.id, createdFields: result.fields.asJsReadonlyArrayView() }
    } else {
      throw Error('Error creating or updating type')
    }
  }

  const deleteType = async (typeId: EntityTypeId): Promise<void> => {
    const type = await entityTypeRepository.get(typeId)
    try {
      await entityTypeRepository.removeEntityType(typeId)
      showSnackBar(`Deleted type ${type.name}`, 'success')
      navigate(-1)
    } catch (e) {
      logException(e)
      showSnackBar(`Failed to delete type ${type.name}`, 'error')
    }
  }

  const submitEntities = async (entityTypeName: string | undefined, kind: EntityTypeKind | undefined) => {
    const currentState = state as LoadedCsv
    const fieldsToCreate = prepareFieldsToCreate(currentState.fields)
    const label = currentState.labelUpdated ? createEntityLabel(currentState) : undefined
    const { typeId, createdFields } = await submitType(entityTypeName, fieldsToCreate, label, kind)

    const fields = mergeCreatedFields(currentState.fields, createdFields)
    const allFieldsInOrder = extractPopulatedFieldsInOrder(fields)

    const validationOutcome = await createValidatedFieldValues(
      currentState.data,
      allFieldsInOrder,
      entityRepository.getEntityTypeIds.bind(entityRepository)
    )
    if (!(validationOutcome instanceof Success)) throw Error('Error validating entities')

    const fieldValues = (validationOutcome as Success<readonly Map<FieldId, FieldValue>[]>).value

    const entities = prepareEntities(typeId, fieldValues)
    await entityRepository.createEntities(entities)

    return typeId
  }

  const setFields = async (fields: readonly FieldData[]): Promise<void> => {
    const currentState = state as LoadedCsv
    const fieldsWithValuesInOrder = fields
      .flatMap((field) => (isPopulated(field) ? [field] : []))
      .sort((a, b) => a.columnIndex - b.columnIndex)

    const entityValidationOutcome = await createValidatedFieldValues(
      currentState.data,
      fieldsWithValuesInOrder,
      entityRepository.getEntityTypeIds.bind(entityRepository)
    )

    const newState = { ...currentState, fields }
    if (!(entityValidationOutcome instanceof Failure))
      setState(withUpdatedTypeValidations(withEntityValidationErrors(newState, undefined)))
    else setState(withUpdatedTypeValidations(withEntityValidationErrors(newState, entityValidationOutcome.value)))
  }

  const setEntityTypeName = (value: string) =>
    setState(withUpdatedNameValidation({ ...(state as LoadedCsv), entityTypeName: value }))

  const setPrimaryLabel = (value?: string) =>
    setState(withLabelUpdated(withUpdatedLabelValidation({ ...(state as LoadedCsv), primaryLabel: value }), true))

  const setSecondaryLabel = (value?: string) =>
    setState(withLabelUpdated(withUpdatedLabelValidation({ ...(state as LoadedCsv), secondaryLabel: value }), true))

  const setKind = (kind: EntityTypeKind) => setState({ ...(state as LoadedCsv), kind })

  const processUploadedFile = (file: File) =>
    processFile(
      configure,
      state as AwaitingCsvUpload,
      setState,
      file,
      entityRepository.getEntityTypeIds.bind(entityRepository)
    )

  const submitEntitiesCallback = () => {
    setState(withSubmitting(state as LoadedCsv))
    submitEntities(state.entityTypeName, state.kind).then(
      (typeId) => {
        showSnackBar('Import successful.', 'success')
        navigate(`../entities/${typeId.asString()}`)
      },
      () => setState(withSubmissionError(state as LoadedCsv | SubmittingChanges))
    )
  }

  const referencedEntityIds: EntityId[] =
    state?.fields
      ?.filter((field) => field.field.type === FieldType.Reference)
      ?.flatMap(
        (field) =>
          field.columnIndex
            ?.let((columnIndex) =>
              state?.data?.let((data) => data.map((row) => entityIdFromStringOrNull(row[columnIndex])))
            )
            ?.filter(isNotUndefinedOrNull) || []
      ) || []

  const relatedEntities = entityRepository.useBatch(referencedEntityIds)

  const error = [state.error && state.errorMessage, entityTypeRepository.useError(), entityRepository.useError()]
    .find((it) => !!it)
    ?.let((it) => String(it))

  useEffect(() => init(), [entityTypeId])

  return (
    <Stack>
      <Collapse in={!!error}>
        <Alert
          variant="filled"
          severity="error"
          sx={{ margin: 2 }}
          action={
            <Button color="inherit" size="small" onClick={() => window.location.reload()}>
              Reload
            </Button>
          }
        >
          <Box>{error}</Box>
          <Box>Please reload the page and try again.</Box>
        </Alert>
      </Collapse>
      <UploadPageContent
        state={state}
        setFields={setFields}
        setEntityTypeName={setEntityTypeName}
        setPrimaryLabel={setPrimaryLabel}
        setSecondaryLabel={setSecondaryLabel}
        setKind={setKind}
        processUploadedFile={processUploadedFile}
        submitEntities={submitEntitiesCallback}
        entityTypeId={entityTypeId}
        relatedEntities={relatedEntities}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        entityTypes={entityTypes}
        indexBeingUpdated={fieldBeingUpdated}
        setIndexBeingUpdated={setFieldBeingUpdated}
        deleteType={deleteType}
      />
      <LoadingOverlay isOpen={!(state.error || state.fields) || state.submitting} />
    </Stack>
  )
}

type UploadPageProps = {
  entityTypeId: EntityTypeId | undefined
  entityTypeRepository: EntityTypeRepository
  configure?: boolean
}

const UploadPage = ({ entityTypeId, entityTypeRepository, configure = false }: UploadPageProps) => {
  const entityTypes = entityTypeRepository.useAll()
  const error = entityTypeRepository.useError()?.let((it) => String(it))

  return (
    <>
      {entityTypes && (
        <ReadyUploadPage
          entityTypeId={entityTypeId}
          entityTypes={entityTypes}
          entityTypeRepository={entityTypeRepository}
          configure={configure}
        />
      )}
      <LoadingOverlay isOpen={!(error || entityTypes)} />
    </>
  )
}

export default UploadPage
export { UploadPageContent }
