import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Alert, Button, Collapse, Fab, Stack, Typography } from '@mui/material'
import { Check } from '@mui/icons-material'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import LoadingOverlay from '../../components/LoadingOverlay'
import { Availability } from '../../domain/form/availability/Availability'
import Program from '../../domain/user/Program'
import getPrograms from '../../api/user/getPrograms'
import sortBy from '../../util/sort/sortBy'
import FormConfiguration from '../../domain/form/FormConfiguration'
import getFormConfiguration from '../../api/form/getFormConfiguration'
import putFormConfiguration from '../../api/form/putFormConfiguration'
import normalizedAvailability from '../../domain/form/availability/normalization/normalizedAvailability'
import prepareForEditing from '../../domain/form/availability/normalization/prepareForEditing'
import prepareForSaving from '../../domain/form/availability/normalization/prepareForSaving'
import FormConfigurationForm from '../../components/form/FormConfigurationForm'
import hasConfigurationErrors from '../../domain/form/hasConfigurationErrors'
import hasConfigurationChanges from '../../domain/form/hasConfigurationChanges'
import { useSnackBar } from '../../components/SnackBarProvider'
import postFormConfiguration from '../../api/form/postFormConfiguration'
import postFormContentText from '../../api/form/postFormContentText'
import { useRefetch } from '../../components/RefetchableData'
import adminAvailability from '../../domain/form/availability/adminAvailability'
import getFormContentText from '../../api/form/getFormContentText'
import { logMessage } from '../../util/Logging'
import { createAuthContext } from '../../api/authorization/AuthenticationContext'
import { requireNotUndefinedOrNull } from '../../util/require'

type EditFormConfigurationPageProps = { formId: string }

const EditFormConfigurationPage = ({ formId }: EditFormConfigurationPageProps) => {
  const navigate = useNavigate()

  const [programs, setPrograms] = useState<readonly Program[]>()
  const [formConfiguration, setFormConfiguration] = useState<FormConfiguration>()
  const [error, setError] = useState<true>()
  const [submitting, setSubmitting] = useState<boolean>(false)

  const [title, setTitle] = useState<string>()
  const [availabilities, setAvailabilities] = useState<readonly Availability[]>()

  const [useYamlFormContent, setUseYamlFormContent] = useState<boolean>(true)

  const [oldFormContent, setOldFormContent] = useState<string>()

  const [formContent, setFormContent] = useState<string>('')
  const [formContentErrorMessage, setFormContentErrorMessage] = useState<string>()

  const { showSnackBar } = useSnackBar()
  const { refetch } = useRefetch()

  const authContext = createAuthContext(useContext<IAuthContext>(AuthContext))
  const { organizationId } = requireNotUndefinedOrNull(authContext)

  const hasChanges = () =>
    (formConfiguration && hasConfigurationChanges(formConfiguration, title, availabilities)) || formContent.trim()

  const fetchPrograms = () =>
    getPrograms({
      authContext: requireNotUndefinedOrNull(authContext),
    }).then(
      (response) => {
        const newPrograms = [...response]
        sortBy(newPrograms, (it) => it.title)
        setPrograms(newPrograms)
        return newPrograms
      },
      () => {
        setError(true)
        return undefined
      }
    )

  const fetchFormConfiguration = (availablePrograms: readonly Program[]) =>
    getFormConfiguration({
      formId,
      authContext: requireNotUndefinedOrNull(authContext),
    }).then(
      (it) => {
        const normalized = {
          ...it,
          availability: it.availability.map((availability) =>
            normalizedAvailability(availability, availablePrograms, organizationId)
          ),
        }
        setFormConfiguration(normalized)
        setTitle(normalized.title)
        setAvailabilities(prepareForEditing(normalized.availability, organizationId))
        return normalized
      },
      () => {
        setError(true)
        return undefined
      }
    )

  const fetchFormContentText = (formContentId: string, useYamlFormat: boolean) =>
    getFormContentText({
      formContentId,
      useYamlFormat,
      authContext: requireNotUndefinedOrNull(authContext),
    }).then(
      (it) => {
        setOldFormContent(it)
        setFormContent(it)
      },
      () => {
        logMessage(`Failed to fetch form content text for contentId ${formContentId}`)
        setOldFormContent('')
        setFormContent('')
      }
    )

  const fetchAll = async () => {
    setError(undefined)
    fetchPrograms().then((availablePrograms) => {
      if (availablePrograms)
        fetchFormConfiguration(availablePrograms).then((configuration) => {
          if (configuration) fetchFormContentText(configuration.contentId, useYamlFormContent)
        })
    })
  }

  const createFormContent = (formContentText: string) =>
    postFormContentText({
      formContentText,
      useYamlFormat: useYamlFormContent,
      authContext: requireNotUndefinedOrNull(authContext),
    })

  const updateFormConfiguration = (
    currentConfiguration: FormConfiguration,
    newTitle: string,
    newAvailabilities: readonly Availability[],
    availablePrograms: readonly Program[],
    contentId: string | undefined = undefined
  ) => {
    const newFormConfiguration = contentId
      ? {
          ...currentConfiguration,
          contentId,
          title: newTitle,
          availability: prepareForSaving(newAvailabilities, availablePrograms, organizationId),
        }
      : {
          ...currentConfiguration,
          title: newTitle,
          availability: prepareForSaving(newAvailabilities, availablePrograms, organizationId),
        }
    return putFormConfiguration({
      formConfiguration: newFormConfiguration,
      authContext: requireNotUndefinedOrNull(authContext),
    })
  }

  const updateForm = (
    currentConfiguration: FormConfiguration,
    newTitle: string,
    newAvailabilities: readonly Availability[],
    availablePrograms: readonly Program[],
    newContent: string
  ) => {
    setSubmitting(true)
    if (newContent.trim() !== '') {
      return createFormContent(newContent).then(
        (contentId) =>
          updateFormConfiguration(currentConfiguration, newTitle, newAvailabilities, availablePrograms, contentId),
        (err) => {
          setFormContentErrorMessage(err.message)
          throw Error()
        }
      )
    } else {
      return updateFormConfiguration(currentConfiguration, newTitle, newAvailabilities, availablePrograms)
    }
  }

  useEffect(() => {
    fetchAll()
  }, [])

  return (
    <>
      <Stack padding={2}>
        <Collapse in={error}>
          <Alert
            variant="filled"
            severity="error"
            sx={{ marginX: 2, marginTop: 2, marginBottom: 5 }}
            action={
              !(programs && formConfiguration) && (
                <Button color="inherit" size="small" onClick={() => fetchAll()}>
                  Refresh
                </Button>
              )
            }
          >
            {programs && formConfiguration
              ? 'Failed to submit changes to form. Please try again.'
              : 'Failed to load form. Please try again.'}
          </Alert>
        </Collapse>
        {formConfiguration && title !== undefined && availabilities && programs && organizationId && (
          <>
            <Fab
              variant="extended"
              color="primary"
              disabled={!hasChanges() || hasConfigurationErrors(title, availabilities)}
              onClick={() =>
                updateForm(formConfiguration, title, availabilities, programs, formContent).then(
                  () => {
                    setSubmitting(false)
                    setError(undefined)
                    showSnackBar('Successfully updated form', 'success')
                    refetch()
                    return navigate('..', { relative: 'path' })
                  },
                  () => {
                    setSubmitting(false)
                    setError(true)
                  }
                )
              }
              sx={{
                padding: 3,
                position: 'fixed',
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(2),
              }}
            >
              <Check sx={{ marginRight: 1 }} />
              Submit
            </Fab>
            <Stack spacing={3}>
              <Typography variant="h4">Editing &quot;{formConfiguration?.title}&quot;</Typography>
              <FormConfigurationForm
                title={title}
                availabilities={availabilities}
                programs={programs}
                organizationId={organizationId}
                useYamlFormContent={useYamlFormContent}
                oldFormContent={oldFormContent}
                formContent={formContent}
                formContentErrorMessage={formContentErrorMessage}
                onTitleChange={setTitle}
                onAvailabilitiesChange={setAvailabilities}
                onUseYamlFormContentChange={(isChecked) => {
                  setUseYamlFormContent(isChecked)
                  fetchFormContentText(formConfiguration.contentId, isChecked)
                }}
                onFormContentChange={setFormContent}
              />
            </Stack>
          </>
        )}
      </Stack>
      <LoadingOverlay isOpen={submitting || (!(programs && formConfiguration) && !error)} />
    </>
  )
}

const CreateFormConfigurationPage = () => {
  const navigate = useNavigate()

  const [programs, setPrograms] = useState<readonly Program[]>()
  const [error, setError] = useState<true>()
  const [submitting, setSubmitting] = useState<boolean>(false)

  const [title, setTitle] = useState<string>('')
  const [availabilities, setAvailabilities] = useState<readonly Availability[]>()

  const [useYamlFormContent, setUseYamlFormContent] = useState<boolean>(false)
  const [formContent, setFormContent] = useState<string>('')
  const [formContentErrorMessage, setFormContentErrorMessage] = useState<string>()

  const [organizationId, setOrganizationId] = useState<string>()

  const { showSnackBar } = useSnackBar()
  const { refetch } = useRefetch()

  const authContext = createAuthContext(useContext<IAuthContext>(AuthContext))

  const fetchPrograms = () =>
    getPrograms({ authContext: requireNotUndefinedOrNull(authContext) }).then(
      (response) => {
        const newOrganizationId = requireNotUndefinedOrNull(authContext).organizationId

        const newPrograms = [...response]
        sortBy(newPrograms, (it) => it.title)
        setPrograms(newPrograms)

        setOrganizationId(newOrganizationId)
        setAvailabilities([adminAvailability(newOrganizationId)])

        return newPrograms
      },
      () => {
        setError(true)
        return undefined
      }
    )

  const createFormContent = (formContentText: string) =>
    postFormContentText({
      formContentText,
      useYamlFormat: useYamlFormContent,
      authContext: requireNotUndefinedOrNull(authContext),
    })

  const createForm = (
    newTitle: string,
    newAvailabilities: readonly Availability[],
    newFormContent: string,
    availablePrograms: readonly Program[]
  ) => {
    setSubmitting(true)
    return createFormContent(newFormContent).then(
      (contentId) =>
        postFormConfiguration({
          formConfigurationToCreate: {
            title: newTitle,
            contentId,
            availability: prepareForSaving(
              newAvailabilities,
              availablePrograms,
              requireNotUndefinedOrNull(authContext).organizationId
            ),
          },
          authContext: requireNotUndefinedOrNull(authContext),
        }),
      (err) => {
        setFormContentErrorMessage(err.message)
        throw Error()
      }
    )
  }

  useEffect(() => {
    fetchPrograms()
  }, [])

  return (
    <>
      <Stack padding={2}>
        <Collapse in={error}>
          <Alert
            variant="filled"
            severity="error"
            sx={{ marginX: 2, marginTop: 2, marginBottom: 5 }}
            action={
              !programs && (
                <Button color="inherit" size="small" onClick={() => fetchPrograms()}>
                  Refresh
                </Button>
              )
            }
          >
            {programs
              ? 'Failed to submit changes to form. Please try again.'
              : 'Failed to load form. Please try again.'}
          </Alert>
        </Collapse>
        {title !== undefined && availabilities && programs && organizationId && (
          <>
            <Fab
              variant="extended"
              color="primary"
              disabled={hasConfigurationErrors(title, availabilities) || formContent?.trim() === ''}
              onClick={() =>
                createForm(title, availabilities, formContent, programs).then(
                  () => {
                    setSubmitting(false)
                    setError(undefined)
                    showSnackBar('Successfully created form', 'success')
                    refetch()
                    navigate('..', { relative: 'path' })
                  },
                  () => {
                    setSubmitting(false)
                    setError(true)
                  }
                )
              }
              sx={{
                padding: 3,
                position: 'fixed',
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(2),
              }}
            >
              <Check sx={{ marginRight: 1 }} />
              Submit
            </Fab>
            <Stack spacing={3}>
              <Typography variant="h4">Creating &quot;{title}&quot;</Typography>
              <FormConfigurationForm
                title={title}
                availabilities={availabilities}
                programs={programs}
                organizationId={organizationId}
                useYamlFormContent={useYamlFormContent}
                formContent={formContent}
                formContentErrorMessage={formContentErrorMessage}
                onTitleChange={setTitle}
                onAvailabilitiesChange={setAvailabilities}
                onUseYamlFormContentChange={setUseYamlFormContent}
                onFormContentChange={setFormContent}
              />
            </Stack>
          </>
        )}
      </Stack>
      <LoadingOverlay isOpen={submitting || (!programs && !error)} />
    </>
  )
}

const FormConfigurationPage = () => {
  const params = useParams()
  return params.formId ? <EditFormConfigurationPage formId={params.formId} /> : <CreateFormConfigurationPage />
}

export default FormConfigurationPage
